html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    /* margin:0; */
    padding:0;
    border:0;
    outline:0;
    /* font-size:100%; */
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;  
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}



.Nav {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  background-color: #212121;
  /* box-shadow: 1px 1px 3px grey; */
  z-index: 11;
}

.UnderNav {
  position: absolute;
  margin-top: 76px;
  width: 100%;
}

.Nav h3 {
  margin: 0px;
      /* margin-left: 50px; */
      color: white;
      font-size: 2.0rem;
      cursor: pointer;
}

.innerNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  font-family: 'Montserrat', sans-serif;
}

.innerNav i {
  color: white;
}

.innerNav div {
  display: flex;
  justify-content: baseline;
  margin-top: 5px;
}

.innerNav div i {
  margin-right: 20px;
  cursor: pointer;
}

.innerNav div i:hover {
  color: lightgreen;
}

.innerNav div button {
  height: 32px;
  background: black;
  border: 2px solid white;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;
}

.innerNav div button:hover {
  color: lightgreen;
  border: 2px solid lightgreen;
  
}

.blueLogo {
  width: 55px;
  height: 41px;
  margin-right: 15px;
}

.LoginNavButton {
  margin-right: 10px;
}

@media (max-width: 450px) {

  /* .Nav {
    height: 90px;
  } */

  .Nav .innerNav div .fa-plus-square {
    display: none;
  }

  .Nav .innerNav {
    justify-content: baseline;
  }

  .ArticleHubLogo .blueLogo {
    width: 30px;
    height: 20px;
    margin-right: 5px;
    margin-top: 5px;
  }

  .ArticleHubLogo h3 {
    font-size: 1.5rem;
  }

  .innerNav .ArticleHubLogo {
    margin-top: 10px;
  }


}

@media (max-width: 380px) {


  .ArticleHubLogo h3 {
    font-size: 1.1rem;
  }

  .ArticleHubLogo .blueLogo {
    margin-top: 2px;
    width: 22px;
    height: 18px;
  }

  .innerNav div button {
    font-size: 12px;
  }

}

@media (max-width: 300px) {
  .ArticleHubLogo h3 {
    font-size: 1rem;
  }

  .ArticleHubLogo .blueLogo {
    margin-top: 0px;
    width: 22px;
    height: 18px;
  }

  .innerNav div button {
    font-size: 10px;
  }
}